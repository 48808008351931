import {FC} from "react";

import "../styles/NavigationButton.css"

const NavigationButton: FC<{linkName: string, buttonName: string, link: string}> = (props) => {
    return (
        <div id={props.buttonName}>
            <a href={props.link} className="navbutton">{props.linkName}</a>
        </div>
    )
}

export default NavigationButton