import React from 'react';
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home";

function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<Home />}>
                <Route path=":name" element={<Home />} />
            </Route>
        </Routes>
      </Router>
  );
}

export default App;
