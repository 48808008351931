import {FC} from "react";

import "../styles/TextBox.css"

const TextBox: FC<{text: string, title: string}> = (props) => {
    return (
        <div className="information">
            <h1 className="infotitle">{props.title}</h1>
            <p className="infotext">{props.text}</p>
        </div>
    )
}

export default TextBox