import {Fragment, useEffect} from "react";
import "../styles/Home.css"
import {useParams} from "react-router-dom";
import NavigationButton from "../components/NavigationButton";
import TextBox from "../components/TextBox";

const Home = () => {
    const params = useParams()
    useEffect(() => {
        document.title = "Home"
    }, [])
    return (
        <Fragment>
            <p className="title">Hello! I'm <span className="name">{params.name ? params.name : "Taaha Sayed"}</span></p>
            <p className="subtitle">Software Engineer</p>
            <ul id="buttons">
                <li><NavigationButton linkName="Github" buttonName="githubButton" link="https://github.com/Taahh" /></li>
                <li><NavigationButton linkName="Youtube" buttonName="youtubeButton" link="https://youtube.com/Taahh" /></li>
            </ul>
            <TextBox text="Hello! I'm Taaha Sayed! I'm currently a 17 year old High School student studying CS. I started self-studying CS around the age of 11, with the help of many peers. I've studied Java during most of this time period, and have studied HTML, CSS, JS/TS, PHP, Lua, C#, and Python." title="About Me" />
            <ul id="footer">
                <p className="discord"><img src="https://www.freepnglogos.com/uploads/discord-logo-png/discord-logo-logodownload-download-logotipos-1.png"  alt="discord"/>&nbsp;&nbsp;&nbsp;<span>Taah#0001 <a href="https://discord.gg/RXsw9BvnpX">(LINK)</a></span></p>
                <p className="twitter"><img src="https://www.iconpacks.net/icons/2/free-twitter-logo-icon-2429-thumb.png"  alt="twitter"/>&nbsp;&nbsp;&nbsp;<span>@taahxic <a href="https://twitter.com/taahxic">(LINK)</a></span></p>
                {/*<p className="email"><img src="http://assets.stickpng.com/thumbs/58485698e0bb315b0f7675a8.png"  alt="email"/>&nbsp;&nbsp;&nbsp;<span></span></p>*/}
            </ul>
        </Fragment>
    )
}

export default Home